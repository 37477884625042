<template>
	<div class="admin-common">
		<a-layout class="ac-layout">
			<a-layout-sider v-model:collapsed="show.collapsed" :trigger="null" collapsible class="ac-slide" width="180">
				<a-menu 
				theme="dark" 
				mode="inline" 
				v-model:selectedKeys="show.selectedKeys" 
				v-model:openKeys="show.openKeys" 
				@select="selectMenu"
				@openChange="onOpenChange">
					<template v-for="(item,index) in perm">
						<template v-if="item.children.length == 0">
							<a-menu-item :key="index" @click="navChange(item)">
								<i :class="item.menu_icon" class="ri-lg ri-top"></i>
								<span  v-if="!show.collapsed" class="menu-title">{{ item.title }}</span>
							</a-menu-item>
						</template>
						<a-sub-menu :key="index" v-else>
							<template #title>
								<span>
									<i :class="item.menu_icon" class="ri-lg ri-top"></i>
									<span v-if="!show.collapsed" class="menu-title">{{ item.title }}</span>
								</span>
							</template>
							<a-menu-item :key="index+'-'+(ind+1)" v-for="(val,ind) in item.children" @click="navChange(val)">{{ val.title }}</a-menu-item>
						</a-sub-menu>
					</template>
				</a-menu>
			</a-layout-sider>
			<a-layout>
				<a-layout-header class="ac-layout-header" style="background: #fff; padding: 0 24px">
					<a-space>
						<i class="ri-lg" :class="show.collapsed ?'ri-menu-unfold-line':'ri-menu-fold-line'" @click="show.collapsed =!show.collapsed"></i>
						<i class="ri-refresh-line ri-lg" @click="reload()"></i>
					</a-space>
					<a-space>
						<a-badge :count="$store.getters.statisticsInfo && $store.getters.statisticsInfo.is_read" dot>
							<span @click="showDrawer">消息通知</span>
						</a-badge>
						<a-divider type="vertical" />
						<span @click="clearCache">更新缓存</span>
						<a-divider type="vertical" />
						<a-dropdown>
							<span>{{ $store.state.username}}<i class="ri-arrow-down-s-fill ri-top"></i></span>
							<template #overlay>
								<a-menu>
									<router-link to="/many_store/public/myInfo">
										<a-menu-item>我的账号</a-menu-item>
									</router-link>
									<a-menu-item @click="logout">退出</a-menu-item>
								</a-menu>
							</template>
						</a-dropdown>
						
					</a-space>
					
				</a-layout-header>
				<a-layout-content :style="{ margin: '24px 16px', minHeight: '280px' }">
					<router-view></router-view>
				</a-layout-content>
			</a-layout>
		</a-layout>

		<a-drawer placement="right" :closable="false" v-model:visible="show.drawer" width="500px">
			<template #title>
				<span>我的消息</span>
				<span style="font-size: 12px;cursor: pointer;" @click="readAll">全部已读</span>
			</template>
			<div class="infinite-list" style="overflow:auto">
				<div v-if="drawerData.list.length>0">
				<div class="kpd-item" v-for="(item,index) in drawerData.list" :key="index" @click="messageToDetail(item)">
					   <div>
						   <div class="kpdi-content">{{item.title}}</div>
						   <div class="kpdi-time">{{item.create_time}}</div>
					   </div>
					</div>
				</div>
				
				<div v-else>
					<span style="margin-left: 5%;font-size: 12px;color: #999;">暂时没有任何消息通知</span>
				</div>
		    </div>
			<div class="pager">
				<a-pagination
					show-size-changer
					:default-current="drawerData.page"
					:total="drawerData.count"
					:pageSize="drawerData.limit"
					@showSizeChange="(p,e)=>{getMessage(drawerData.page,e)}"
					@change="(e)=>{getMessage(e,drawerData.limit)}"
				/>
			</div>
		</a-drawer>
	</div>
</template>
<script>
import { getCurrentInstance, reactive, toRefs } from 'vue';
import commonModel from '@/api/common';
import login from '@/api/login';
import store from '@/store'
import router from '@/router'
export default {
	inject:["reload"],
	setup(){
		const state = reactive({
			perm: [],
			temp: [], //组装成想要的菜单信息的中间变量
			is_shop: 0,
			show: {
				collapsed: false,
				selectedKeys: [],
				openKeys:[],
				drawer:false
			},
			drawerData:{
				list:[],
				page:0,
				count:0,
				limit:20
			},
		})
		if( localStorage.getItem('sass_nav_key')){
			let key = localStorage.getItem("sass_nav_key")
			let str = key.split("-")
			state.show.selectedKeys = key == '0' ? [parseInt(key)] :[key]
			state.show.openKeys = [parseInt(str[0])]
		}

		let userInfo = JSON.parse(localStorage.getItem('kdFarmUserInfo'));
		store.dispatch('setUserInfo',userInfo)
        state.perm = store.state.permissionList


		function navChange(data){
			router.push(data.path)
		}

		//菜单被选中
		function selectMenu(e){
			localStorage.setItem("sass_nav_key",e.key)
			state.show.selectedKeys = [e.key]
		}
		function onOpenChange(openKeys){
			state.show.openKeys = [openKeys[openKeys.length-1]]
		}

		function showDrawer(){
			state.show.drawer = true
			state.drawerData.page = 0
			state.drawerData.list = []
			getMessage(1,state.drawerData.limit)
		}

		function getMessage(page,limit){
			commonModel.getNewMessge(page,limit,null,res=>state.drawerData = {limit,...res})
		}

		const clearCache = ()=> commonModel.clearCache()

		const logout = ()=>login.logout()

		return{
			...toRefs(state),
			selectMenu,
			navChange,
			onOpenChange,
			clearCache,
			logout,
			showDrawer,
			getMessage
		}
	}
}
</script>
<style lang="scss" scoped>
.ac-layout {
	height: 100%;
	width: 100%;
	position: fixed;
	left: 0;
	top: 0;
	.menu-title{
		margin-left: 12px;
	}
	
	&-header{
		cursor: pointer;
		display: flex;
		justify-content: space-between;
	}
}
.kpd-item{
	width: 90%;
	margin: auto;
	padding: 10px 0;
	border-bottom: 1px solid #f4f4f4;
	display: flex;
	align-items: center;
	cursor: pointer;
	
	.kpdi-content{
		font-size: 12px;
		color: #333;
	}
	.kpdi-time{
		font-size: 12px;
		color: #999;
		margin-top: 5px;
	}
	div:first-child{
		margin-right: 5px;
	}
}
</style>
